import React from 'react'
import loader from "../../../Assets/props/loader_logo.gif"

const PageLoading = () => {
  const items = Array(20).fill(0);

  return (
    <div className=''>
      <div style={{ display: 'flex', padding: '1rem', justifyContent: 'center', flexWrap: 'wrap', gap: '1rem' }} className='mainDivProduct'>
        {items.map((_, i) => {
          return (
            <div className='productdiv' key={i}>
              <img src={loader} className='bg-red-600' alt="loader" />
              <p className='ParaInDiv'>Gem Experience</p>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default PageLoading