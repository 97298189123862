import React, { useState } from 'react'

const Pagination = ({ page, totalPages, onPageChange }) => {
    const [inputPage, setInputPage] = useState(page);

    const handleInputChange = (e) => {
        const value = e.target.value;
        if (value === '' || /^[0-9\b]+$/.test(value)) {
            setInputPage(value);
        }
    };

    const handleJumpToPage = () => {
        const pageNumber = parseInt(inputPage, 10);
        if (pageNumber > 0 && pageNumber <= totalPages) {
            onPageChange(pageNumber);
        } else {
            setInputPage(page); // Reset to current page if out of bounds
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleJumpToPage();
        }
    };
    return (
        <div className='py-8 flex flex-col items-center gap-3 text-sm md:text-base'>
            <div className='flex flex-wrap justify-around items-center w-full font-bold'>
                <button onClick={() => onPageChange(page - 1)} disabled={page === 1} className='flex items-center disabled:cursor-not-allowed disabled:text-gray-500'>
                    Previous
                </button>
                <span>Page {page} of {totalPages}</span>
                <button onClick={() => onPageChange(page + 1)} disabled={page === totalPages} className='flex items-center disabled:cursor-not-allowed disabled:text-gray-500'>
                    Next
                </button>
            </div>
            <label>
                Jump to
                <input
                    type='number'
                    value={inputPage}
                    onChange={handleInputChange}
                    onKeyPress={handleKeyPress}
                    className='w-9 border rounded-lg outline-none pl-1 mx-2'
                />
                page
                <button
                    onClick={handleJumpToPage}
                    className='ml-2 px-3 py-1 bg-[#102b58] text-white text-xs rounded-lg'>
                    Go
                </button>
            </label>
        </div>
    )
}

export default Pagination